/* global angular, _ , ga */
import CategoryGridController from './modules/components/category-grid/category-grid'
import MyHomeController from './modules/main/home/my-home'
import DevicesController from './modules/main/devices/devices.controller'
import DeviceShowController from './modules/main/devices/device-show.controller'

/** @ngInject */
function routeConfig (
  $logProvider,
  $stateProvider,
  $urlRouterProvider,
  msNavigationServiceProvider
) {
  // $locationProvider.html5Mode(true);

  $urlRouterProvider.otherwise('/login')

  /**
   * Layout Style Switcher
   *
   * This code is here for demonstration purposes.
   * If you don't need to switch between the layout
   * styles like in the demo, you can set one manually by
   * typing the template urls into the `State definitions`
   * area and remove this code
   */
  // Inject $cookies
  let $cookies

  angular.injector(['ngCookies']).invoke([
    '$cookies',
    function (_$cookies) {
      $cookies = _$cookies
    }
  ])

  // Get active layout
  let layoutStyle = $cookies.get('layoutStyle') || 'horizontalNavigation'
  const layouts = {
    verticalNavigation: {
      main: 'modules/core/layouts/vertical-navigation.html',
      toolbar: 'modules/toolbar/layouts/vertical-navigation/toolbar.html',
      navigation:
        'modules/navigation/layouts/vertical-navigation/navigation.html'
    },
    verticalNavigationFullwidthToolbar: {
      main: 'modules/core/layouts/vertical-navigation-fullwidth-toolbar.html',
      toolbar:
        'modules/toolbar/layouts/vertical-navigation-fullwidth-toolbar/toolbar.html',
      navigation:
        'modules/navigation/layouts/vertical-navigation/navigation.html'
    },
    verticalNavigationFullwidthToolbar2: {
      main: require('./modules/core/layouts/vertical-navigation-fullwidth-toolbar-2.html'),
      toolbar: require('./modules/toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.html'),
      navigation: require('./modules/navigation/layouts/vertical-navigation-fullwidth-toolbar-2/navigation.html')
    },
    horizontalNavigation: {
      main: require('./modules/core/layouts/horizontal-navigation.html'),
      toolbar: require('./modules/toolbar/layouts/vertical-navigation-fullwidth-toolbar-2/toolbar.html'),
      navigation: require('./modules/navigation/layouts/horizontal-navigation/navigation.html')
    },
    contentOnly: {
      main: 'modules/core/layouts/content-only.html',
      toolbar: '',
      navigation: ''
    },
    contentWithToolbar: {
      main: 'modules/core/layouts/content-with-toolbar.html',
      toolbar: 'modules/toolbar/layouts/content-with-toolbar/toolbar.html',
      navigation: ''
    }
  }
  // Force style
  layoutStyle = 'verticalNavigationFullwidthToolbar2'
  // State definitions
  $stateProvider
    .state('app', {
      abstract: true,
      resolve: {
        initSocketConnection: nextplusSocket => {
          return nextplusSocket.connect()
        },
        getProjectSettings: (
          $http,
          $rootScope,
          $translate,
          LoginService,
          $window,
          msNavigationService,
          LanguageUtils,
          Menus
        ) => {
          return new Promise(async (resolve, reject) => {
            $rootScope.msNavigationServiceProvider = msNavigationServiceProvider
            const res = await $http.get('api/getSettings')
            const data = res.data
            const settingsNames = Object.keys(data)
            $rootScope.clientNow = new Date()
            for (let s = 0; s < settingsNames.length; s++) {
              const settingName = settingsNames[s]
              $rootScope.appSettings[settingName] = data[settingName]
            }
            $rootScope.appSettings.now = new Date($rootScope.appSettings.now)
            $rootScope.timeDiff =
              $rootScope.appSettings.now - $rootScope.clientNow
            await LoginService.getCurrentUserSingleton()
            LoginService.initSocket()
            // Try initilaize google analytics (will not work on dev mode)
            if (
              $rootScope.appSettings.gaTrackingCode !== '' &&
              $rootScope.appSettings.gaEnable
            ) {
              console.log('Initilaize google analytics')
              try {
                $window.ga =
                  $window.ga ||
                  function () {
                    ;(ga.q = ga.q || []).push(arguments)
                  }
                ga.l = +new Date()
                ga('create', $rootScope.appSettings.gaTrackingCode, 'auto')
                ga('send', 'pageview')
              } catch (e) {
                console.error('error initilaize google analytics', e)
              }
            }
            if ($rootScope.appSettings.css) {
              document.getElementById('inlinecssreplace').innerHTML =
                $rootScope.appSettings.css
            }
            if ($rootScope.appSettings.NODE_ENV === 'development') {
              $logProvider.debugEnabled(true)
            }

            /*
              Setting the app language by the following logic and priority:
                1. The loginService first set the user's language in case the user is already logged in
                2. If the user not logged in and language has not been set ($transalte.use() returns undefined)
                  a. Get the browser language, checks if it within the available languages defined in the system settings and set it
                  b. Use the default language defined in the system settings
            */
            let chosenLanguage = $rootScope.appSettings.language
            const userLanguage = LoginService.currentUser?.lang
            const browserLanguage = (
              $window.navigator.userLanguage || $window.navigator.language
            ).split('-')[0]
            const availableLanguages = $rootScope.appSettings.availableLanguages
            if (availableLanguages.includes(userLanguage)) {
              chosenLanguage = userLanguage
            } else if (availableLanguages.includes(browserLanguage)) {
              chosenLanguage = browserLanguage
            } else if (!availableLanguages.includes(chosenLanguage)) {
              console.warn(
                'The system language is not in the list of available languages.'
              )
            }
            LanguageUtils.language = chosenLanguage
            // $translate.use(chosenLanguage)

            $rootScope.flattenMenuItems = _.cloneDeep(
              msNavigationService.getFlatNavigation()
            )
            resolve()
          })
        }
      },
      views: {
        'main@': {
          template: layouts[layoutStyle].main,
          controller: 'MainController',
          controllerAs: 'vm'
        },
        'toolbar@app': {
          template: layouts[layoutStyle].toolbar,
          controller: 'ToolbarController as vm'
        },
        'navigation@app': {
          template: layouts[layoutStyle].navigation,
          controller: 'NavigationController',
          controllerAs: 'vm'
        }
      }
    })
    .state('app.category-grid', {
      url: '/category-grid/?:category',
      views: {
        'content@app': {
          template: require('./modules/components/category-grid/category-grid.html'),
          controller: CategoryGridController,
          controllerAs: 'vm'
        }
      },
      bodyClass: 'category-grid',
      resolve: {
        ResolvedCategories: function (
          Category,
          MultiTranslateService,
          $rootScope
        ) {
          return Category.find({}).$promise.then(res => {
            res.forEach(category => {
              category = MultiTranslateService.getForView(
                Category,
                $rootScope.currentLang,
                category
              )
            })
            return res
          })
        },
        ResolvedCurrentCategory: function (
          Category,
          $stateParams,
          MultiTranslateService,
          $rootScope
        ) {
          if (
            _.isUndefined($stateParams.category) ||
            $stateParams.category === ''
          ) {
            return null
          }
          return Category.findOne({
            filter: { where: { id: $stateParams.category } }
          }).$promise.then(res =>
            MultiTranslateService.getForView(
              Category,
              $rootScope.currentLang,
              res
            )
          )
        },
        ResolvedWorkflows: function (
          Workflow,
          $stateParams,
          $rootScope,
          MultiTranslateService
        ) {
          const workflowFilter = {
            filter: {
              fields: {
                id: true,
                categories: true,
                name: true,
                tags: true,
                latestVersion: true,
                ResourceId: true,
                recordId: true,
                translations: true,
                fallbackStatus: true,
                partSku: true,
                partRev: true,
                parts: true,
                created: true
              }
            }
          }
          if (
            _.isUndefined($stateParams.category) ||
            $stateParams.category === ''
          ) {
            workflowFilter.filter.where = {
              and: [
                { latestVersion: true },
                {
                  or: [{ categories: { exists: false } }, { categories: [] }]
                }
              ]
            }
          } else {
            workflowFilter.filter.where = {
              and: [
                { latestVersion: true },
                { categories: { inq: [$stateParams.category] } }
              ]
            }
          }
          return Workflow.find(workflowFilter).$promise.then(async res => {
            res.forEach(workflow => {
              workflow = MultiTranslateService.getForView(
                Workflow,
                $rootScope.currentLang,
                workflow
              )
            })
            return res
          })
        },
        ResolvedContentPackages: function (
          ContentPackage,
          $stateParams,
          $rootScope,
          MultiTranslateService
        ) {
          const contentPackagesFilter = {
            filter: {
              fields: {
                id: true,
                categories: true,
                name: true,
                tags: true,
                image: true,
                translations: true,
                fallbackStatus: true
              }
            }
          }
          if (
            _.isUndefined($stateParams.category) ||
            $stateParams.category === ''
          ) {
            contentPackagesFilter.filter.where = {
              or: [{ categories: { exists: false } }, { categories: [] }]
            }
          } else {
            contentPackagesFilter.filter.where = {
              categories: { inq: [$stateParams.category] }
            }
          }
          return ContentPackage.find(contentPackagesFilter).$promise.then(res =>
            res.map(contentPackage =>
              MultiTranslateService.getForView(
                ContentPackage,
                $rootScope.currentLang,
                contentPackage
              )
            )
          )
        },
        ResolvedCatalog: function (Catalog, $stateParams) {
          const catalogFilters = { filter: {} }
          if (
            _.isUndefined($stateParams.category) ||
            $stateParams.category === ''
          ) {
            catalogFilters.filter.where = {
              or: [{ categories: { exists: false } }, { categories: [] }]
            }
          } else {
            catalogFilters.filter.where = {
              categories: { inq: [$stateParams.category] }
            }
          }
          return Catalog.find(catalogFilters).$promise
        },
        ResolvedWiringlist: function (Wiringlist, $stateParams) {
          const wiringlistFilters = { filter: {} }
          if (
            _.isUndefined($stateParams.category) ||
            $stateParams.category === ''
          ) {
            wiringlistFilters.filter.where = {
              or: [{ categories: { exists: false } }, { categories: [] }]
            }
          } else {
            wiringlistFilters.filter.where = {
              categories: { inq: [$stateParams.category] }
            }
          }
          return Wiringlist.find(wiringlistFilters).$promise
        },
        ResolvedTimeline: function (Timeline, $stateParams) {
          const timelineFilters = { filter: {} }
          if (
            _.isUndefined($stateParams.category) ||
            $stateParams.category === ''
          ) {
            timelineFilters.filter.where = {
              or: [{ categories: { exists: false } }, { categories: [] }]
            }
          } else {
            timelineFilters.filter.where = {
              categories: { inq: [$stateParams.category] }
            }
          }
          return Timeline.find(timelineFilters).$promise
        }
      }
    })
    .state('app.404', {
      url: '/404',
      views: {
        'main@': {
          template: require('./modules/core/layouts/content-only.html'),
          controller: 'MainController as vm'
        },
        'content@app.404': {
          template: require('./templates/404.html'),
          controller: () => {}
        }
      },
      bodyClass: '404'
    })
    .state('app.error-page', {
      url: '/error-page/?:{errors:json}',
      params: {
        errors: {
          dynamic: true
        }
      },
      views: {
        'main@': {
          template: require('./modules/core/layouts/content-only.html'),
          controller: 'MainController as vm'
        },
        'content@app.error-page': {
          template: require('./templates/error-page.html'),
          controller: ($scope, $stateParams, $state, $translate) => {
            const {
              code,
              statusCode,
              details: { params, codes }
            } = $stateParams.errors
            if (statusCode === 403) {
              // user creation or license error
              if (code === 'LICENSE_ERROR_PER_ROLE') {
                const { roleType, licenseSetting, userCalculation } =
                  codes[code]
                let availableCount = 0
                let maxAllowed = 0
                if (roleType === 'editor') {
                  availableCount = userCalculation.editorCount
                  maxAllowed = licenseSetting.maxEditorCount
                } else {
                  availableCount = userCalculation.viewerCount
                  maxAllowed = licenseSetting.maxViewerCount
                }
                $scope.error = $translate.instant(
                  `SETTINGS.AUTHENTICATION.ERRORS.LICENSE.${code}`,
                  {
                    availableCount,
                    maxAllowed,
                    roleType
                  }
                )
              } else {
                $scope.error = $translate.instant(
                  `SETTINGS.AUTHENTICATION.ERRORS.LICENSE.${code}`,
                  codes[code]
                )
              }
            } else if (statusCode === 401) {
              // concurrency error
              $state.go('app.login', { showConcurrency: true })
            } else {
              // other errors
              $scope.error = $translate.instant(
                $stateParams.errors.code,
                params || {}
              )
            }
          }
        }
      },
      bodyClass: 'error-page'
    })
    .state('app.my-home', {
      url: '/my-home',
      views: {
        'content@app': {
          template: require('./modules/main/home/my-home.html'),
          controller: MyHomeController
        }
      },
      resolve: {
        ResolvedData: function (UserModel) {
          return UserModel.getHomeData().$promise
        },
        ResolvedUsers: function (UserModel) {
          return UserModel.find({
            filter: { fields: { id: true, displayName: true } }
          }).$promise
        },
        ResolvedLocations: function (Location) {
          return Location.find({
            filter: {
              deleted: true
            }
          }).$promise
        },
        ResolvedTypes: function (WorkflowType) {
          return WorkflowType.find({
            filter: {
              deleted: true,
              fields: { id: true, name: true, deletedAt: true }
            }
          }).$promise
        },
        ResolvedWorkorderStatus: function (WorkorderStatus) {
          return WorkorderStatus.find({
            filter: {
              deleted: true,
              fields: { id: true, name: true, deletedAt: true, color: true }
            }
          }).$promise
        }
      },
      bodyClass: 'my-home'
    })
    .state('app.devices', {
      url: '/devices/?:filter',
      params: {
        filter: {
          dynamic: true
        }
      },
      views: {
        'content@app': {
          template: require('./modules/components/tables/kendo-grid/grid.html'),
          controller: DevicesController
        }
      },
      bodyClass: 'devices'
    })
    .state('app.devices.show', {
      url: 'show/:id',
      params: { id: { dynamic: true } },
      views: {
        'content@app': {
          template: require('./modules/main/devices/device-show.template.html'),
          controller: DeviceShowController
        }
      },
      lazyLoad: $transition$ => {
        const $ocLazyLoad = $transition$.injector().get('$ocLazyLoad')
        return import(
          /* webpackChunkName: "kendo.dataviz.chart" */ 'root/node_modules/@progress/kendo-ui/js/kendo.dataviz.chart'
        )
          .then(mod => {
            return $ocLazyLoad.inject(mod.default)
          })
          .catch(err => {
            throw new Error('Ooops, something went wrong, ' + err)
          })
      },
      resolve: {
        ResolvedStock: (Stock, $stateParams) => {
          return Stock.getDetails({
            includeProductTree: true,
            stockId: $stateParams.id
          }).$promise
        },
        ResolvedUsers: function (UserModel) {
          return UserModel.find({
            filter: { fields: { id: true, displayName: true } }
          }).$promise
        },
        ResolvedLocations: function (Location) {
          return Location.find({
            filter: {
              deleted: true
            }
          }).$promise
        },
        ResolvedTypes: function (WorkflowType) {
          return WorkflowType.find({
            filter: {
              deleted: true,
              fields: { id: true, name: true, deletedAt: true }
            }
          }).$promise
        },
        ResolvedWorkorderStatus: function (WorkorderStatus) {
          return WorkorderStatus.find({
            filter: {
              deleted: true,
              fields: { id: true, name: true, deletedAt: true, color: true }
            }
          }).$promise
        }
      },
      bodyClass: 'devices device-show'
    })
    .state('app.devices.view', {
      url: 'view/:sku/:serial',
      resolve: {
        redirect: ($state, $stateParams, Stock) => {
          return new Promise(async (resolve, reject) => {
            const { sku, serial } = $stateParams
            Stock.findOne({
              filter: {
                where: {
                  and: [{ sku }, { serial }]
                },
                fields: { id: true, sku: true, serial: true }
              }
            })
              .$promise.then(stock => {
                resolve($state.go('app.devices.show', { id: stock.id }))
              })
              .catch(err => {
                console.error(err)
                reject(err)
              })
          })
        }
      }
    })
    .state('app.tests', {
      url: '/tests',
      views: {
        'content@app': {
          controller: function (DigestEmail, $scope) {
            $scope.sendDigestEmail = function sendDigestEmail () {
              DigestEmail.createDigestEmail()
            }
          },
          template: `<body >
                      <md-button class="md-accent md-raised" ng-click="sendDigestEmail()">Send Digest Email</md-button>
                    </body>`,
          controllerAs: 'vm'
        }
      },
      bodyClass: 'tests'
    })

  msNavigationServiceProvider.saveItem('apps.my-home', {
    title: 'HOME.MY_HOME',
    icon: 'icon-home',
    state: 'app.my-home'
  })

  msNavigationServiceProvider.saveItem('apps.knowledgeBase', {
    title: 'HOME.KNOWLEDGE_BASE',
    icon: 'icon-head-lightbulb',
    hidden: true,
    weight: 30
  })

  msNavigationServiceProvider.saveItem('apps.modeling', {
    title: 'HOME.MODELING',
    icon: 'icon-puzzle',
    hidden: false,
    weight: 40
  })

  msNavigationServiceProvider.saveItem('apps.knowledgeBase.index', {
    title: 'HOME.INDEX',
    icon: 'icon-file-document-box',
    state: 'app.category-grid',
    weight: 1,
    aclsCondition: 'or',
    acls: [
      { model: 'Workflow', method: 'find' },
      { model: 'Catalog', method: 'find' },
      { model: 'Category', method: 'find' },
      { model: 'ContentPackage', method: 'find' }
    ]
  })

  msNavigationServiceProvider.saveItem('apps.modeling.tests', {
    title: 'tests',
    state: 'app.tests',
    icon: 'icon-ab-testing',
    hidden: true,
    weight: 40
  })
}

module.exports = routeConfig
