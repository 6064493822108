/* global jQuery,$,angular confirm, _ , MouseEvent */
const QueryCache = require('root/../common/services/query-cache')

/** @ngInject */
function DocumentCreateLinkController (
  $scope,
  $rootScope,
  $mdDialog,
  $translate,
  getUrlFromObj,
  DocumentUtils,
  CatalogUtils,
  ContentPackage,
  Workflow,
  Catalog,
  PartAssembly,
  locals
) {
  $scope.selectedTab = 0
  const tabIndexMapping = {
    0: 'Custom',
    1: 'Workflow',
    2: 'ContentPackage',
    3: 'Catalog'
  }
  $scope.contentPackages = null
  $scope.catalogsData = null
  $scope.workflowsData = null
  $scope.$watch('selectedTab', async function (newValue, oldValue) {
    console.log(`selectedTab changed from ${oldValue} to ${newValue}`)
    if (oldValue !== newValue) {
      if (tabIndexMapping[newValue] === 'Custom') {
        $scope.contentPackages = null
        $scope.catalogsData = null
        $scope.workflowsData = null
      } else if (tabIndexMapping[newValue] === 'ContentPackage') {
        $scope.contentPackages = await ContentPackage.find({
          filter: {
            fields: { id: true, name: true, image: true },
            include: ['image_object']
          }
        }).$promise
        $scope.catalogsData = null
        $scope.workflowsData = null
      } else if (tabIndexMapping[newValue] === 'Workflow') {
        $scope.workflowsData = await Workflow.find({
          filter: {
            where: {
              currentReleasedVersion: true,
              releaseStatus: 'released'
            },
            fields: {
              id: true,
              recordId: true,
              name: true,
              ResourceId: true
            },
            include: [{ relation: 'resource' }]
          }
        }).$promise
        $scope.catalogsData = null
        $scope.contentPackages = null
      } else if (tabIndexMapping[newValue] === 'Catalog') {
        $scope.catalogsData = await Catalog.getAllCatalogsWithRootPart()
          .$promise
        $scope.contentPackages = null
        $scope.workflowsData = null
      }
    }
  })
  $scope.absolutePath = locals.absolutePath
  $rootScope.loadingProgress = false
  $scope.getUrlFromObj = getUrlFromObj
  $scope.searchPlaceholder = $translate.instant('CONTENTPACKAGE.SEARCH')
  $scope.linkPlaceholder = $translate.instant('CONTENTPACKAGE.LINK')
  // $scope.contentPackages = ResolvedContentPackages
  // $scope.catalogsData = ResolvedCatalogs
  // $scope.workflowsData = ResolvedWorkflows

  $scope.obj = {
    search: '',
    selectedContentPackage: null,
    selectedDocument: null,
    selectedWorkflow: null,
    selectedCatalog: null,
    selectedPart: null,
    selectedLink: null
  }

  let prefix = ''
  if (locals.absolutePath) {
    prefix = $rootScope.appSettings.config.NEXTPLUS_SITE_URL
  }

  $scope.nodePartClick = (event, node) => {
    event.stopPropagation()
    $scope.obj.selectedPart = node
    $scope.obj.selectedLink =
      prefix +
      CatalogUtils.createLinkForCatalog($scope.obj.selectedCatalog, node)
  }

  $scope.toggle1 = x => x.toggle()

  $scope.nodeDocumentClick = (event, node) => {
    event.stopPropagation()
    $scope.obj.selectedDocument = node
    $scope.obj.selectedLink =
      prefix +
      DocumentUtils.createLinkForDocument(
        $scope.obj.selectedContentPackage,
        $scope.obj.selectedDocument
      )
  }

  $scope.loadPartsByCatalog = cat => {
    $scope.catalog = cat.catalog
    $scope.assembly = CatalogUtils.makeIdTree(cat.assembly)
    $scope.assemblyArray = CatalogUtils.formatNodes([
      CatalogUtils.makeIdTree(cat.assembly)
    ])
  }

  $scope.loadDocumentsByPackage = contentPackage => {
    $scope.data = [
      DocumentUtils.makeIdTree({
        id: contentPackage.id,
        name: contentPackage.name,
        search:
          contentPackage.name +
          ' ' +
          DocumentUtils.getValueForSearch(
            contentPackage.documentStructure,
            $scope.obj.selectedContentPackage.documents
          ),
        nodes: DocumentUtils.formatNodes(
          contentPackage.documentStructure,
          $scope.obj.selectedContentPackage.documents
        )
      })
    ]
  }

  $scope.selectContentPackage = async contentPackage => {
    $rootScope.loadingProgress = true
    try {
      const contentPackageObject = await ContentPackage.findOne({
        filter: { where: { id: contentPackage.id }, include: ['documents'] }
      }).$promise
      $scope.obj.selectedContentPackage = contentPackageObject
      $scope.loadDocumentsByPackage(contentPackageObject)
      $scope.obj.selectedLink =
        prefix +
        DocumentUtils.createLinkForDocument($scope.obj.selectedContentPackage)
      $scope.$applyAsync()
    } catch (err) {
      console.log(err)
    } finally {
      $rootScope.loadingProgress = false
    }
  }

  $scope.selectCatalog = async catalogData => {
    $rootScope.loadingProgress = true
    try {
      const assembly = await PartAssembly.getWithSubParts({
        number: catalogData.partNumber
      }).$promise
      $scope.obj.selectedCatalog = catalogData
      $scope.loadPartsByCatalog({ ...catalogData, assembly: assembly.result })
      $scope.obj.selectedLink =
        prefix + CatalogUtils.createLinkForCatalog($scope.obj.selectedCatalog)

      $scope.$applyAsync()
    } catch (err) {
      console.log(err)
    } finally {
      $rootScope.loadingProgress = false
    }
  }

  $scope.selectWorkflow = workflowData => {
    $scope.obj.selectedWorkflow = workflowData
    $scope.obj.selectedLink = `${prefix}/#!/workflow/session/show/?preview=false&recordId=${workflowData.recordId}`
  }

  $scope.clearSearch = () => ($scope.obj.search = '')

  $scope.cancel = function () {
    $scope.obj.selectedLink = null
    $mdDialog.cancel()
  }

  $scope.link = null
  $scope.clearLink = () => ($scope.link = null)

  $scope.selectElement = () => {
    $scope.link = $scope.obj.selectedLink
    $scope.closeDialog()
  }

  $scope.closeDialog = function () {
    $mdDialog.hide($scope.link ? { link: $scope.link } : '')
  }
}

/** @ngInject */
function MetabaseFrameController (
  $scope,
  $rootScope,
  $mdDialog,
  Rule,
  $element
) {
  $scope.metabaseData = {
    value: `{
      "payload": {
        "resource": { "dashboard": <id> },
      "params": {}
    },
    "urlParams": {
      "titled": false,
      "bordered": false
    }
  }`,
    errorValue: ''
  }

  $scope.save = async () => {
    if ($scope.metabaseData.errorValue) {
      return
    }
    $mdDialog.hide(JSON.stringify(JSON.parse($scope.metabaseData.value)))
  }

  $scope.cancel = function () {
    $mdDialog.cancel()
  }

  $scope.closeDialog = function () {
    $mdDialog.cancel()
  }
}

/** @ngInject */
function runBlock (
  $rootScope,
  $q,
  $mdDialog,
  $interval,
  $timeout,
  $translate,
  $mdToast,
  nextplusSocket,
  $state,
  LoginService,
  $templateCache,
  $window,
  $mdSidenav,
  Page,
  Workflow,
  ContentPackage,
  $animate,
  Catalog,
  $cookies,
  PanelHelper,
  $location,
  NotificationUtils,
  LoopBackAuth,
  $mdMedia,
  timeLogReportService,
  AuthenticationTimeoutService
) {
  $rootScope.mdMedia = $mdMedia
  /* Remove local storage of the old auth */
  $cookies.remove('access_token')
  LoopBackAuth.clearStorage()
  NotificationUtils.init(nextplusSocket)
  const sendEngagement = _.debounce(() => {
    nextplusSocket.socket.emit('engagement')
  }, 2000)

  $rootScope.$on(
    '$stateChangeSuccess',
    async function (event, toState, toParams, fromState, fromParams, options) {
      if ($window.showStateLogs === true) {
        console.log('State name', toState.name)
        const paramsClone = angular.copy(toParams)
        delete paramsClone['#']
        console.log('State params', JSON.stringify(paramsClone))
      }
      if (
        $rootScope.appSettings &&
        $rootScope.appSettings.activationRequired &&
        !$rootScope.activationIsShowed &&
        toState.name !== 'app.login' &&
        toState.name !== 'app.reset-password' &&
        toState.name !== 'app.validate-token' &&
        toState.name !== 'app.reset-sent'
      ) {
        $rootScope.activationIsShowed = true
        const htmlTemplate = `
      <md-dialog style="min-height:76rem;">
        <md-toolbar>
          <div class="md-toolbar-tools">
            <h2 translate="SETTINGS.LICENSE_SETTINGS"></h2>
            <span flex></span>
            <md-button class="md-icon-button" ng-if="!$root.appSettings.NEXTPLUS_DISABLE_TRACKBACK" ng-disabled="disableRefresh" ng-click="refreshSettings()">
              <md-tooltip><span translate="SETTINGS.LICENSE.REFRESH_LICENSE_SETTINGS"></span></md-tooltip>
              <md-icon md-font-icon="icon-refresh"></md-icon>
            </md-button>
            <md-button class="md-icon-button" ng-click="cancel()" ng-if="$root.enforcementMethod === 'soft'">
              <md-icon md-font-icon="icon-close"></md-icon>
            </md-button>
        </div>
        </md-toolbar>
        <md-dialog-content style="min-width: 45em;padding: 10px;">
          <license-serial license-settings="license" users-data="usersStats" callback="serialUpdated()"></license-serial>
        </md-dialog-content>
      </md-dialog>`
        $mdDialog
          .show({
            controller: /* ngInject */ (
              $rootScope,
              $scope,
              $mdDialog,
              $mdToast,
              Setting,
              usersStats,
              locals
            ) => {
              $scope.license = locals.license
              $scope.usersStats = usersStats
              $scope.cancel = () => $mdDialog.cancel()
              $scope.serialUpdated = () => {
                $rootScope.appSettings.activationRequired = false
                $mdDialog.hide()
              }
              $scope.disableRefresh = false

              $scope.refreshSettings = async () => {
                $scope.disableRefresh = true
                try {
                  await Setting.refreshInternalSettings().$promise
                  $window.location.reload()
                } catch (err) {
                  console.error(err)
                  $mdToast.show(
                    $mdToast.nextplus({
                      position: $rootScope.toastLocation,
                      parent: 'body',
                      theme: 'error-toast',
                      hideDelay: 3000
                    })
                  )
                  $mdToast.updateTextContent(
                    $translate.instant(
                      'SETTINGS.LICENSE.REFRESH_LICENSE_SETTINGS_ERROR'
                    )
                  )
                } finally {
                  $scope.disableRefresh = false
                }
              }
            },
            template: htmlTemplate,
            parent: angular.element(document.body),
            targetEvent: '',
            locals: {
              license: $rootScope.appSettings.license
            },
            multiple: true,
            resolve: {
              usersStats: Setting => Setting.getUsersStats().$promise
            },
            fullscreen: true,
            clickOutsideToClose:
              !$rootScope.appSettings.license.enforcementMethod ||
              $rootScope.appSettings.license.enforcementMethod === 'soft',
            escapeToClose:
              !$rootScope.appSettings.license.enforcementMethod ||
              $rootScope.appSettings.license.enforcementMethod === 'soft'
          })
          .then(
            res => {
              $rootScope.activationIsShowed = false
            },
            () => {
              $rootScope.activationIsShowed = false
            }
          )
      } else if (
        LoginService.currentUser &&
        LoginService.currentUser.username !== 'nextplus_system_user'
      ) {
        if (LoginService.currentUser.EULAv11 === null) {
          $mdDialog
            .show({
              controller: require('./common/dialog/eula-dialog/eula.dialog.controller.js'),
              template: require('./common/dialog/eula-dialog/eula.dialog.html'),
              parent: angular.element(document.body),
              multiple: true,
              fullscreen: true,
              clickOutsideToClose: false,
              escapeToClose: false
            })
            .then(() => {
              LoginService.currentUser.EULAv11 = new Date()
            })
        } else if (LoginService.currentUser.shouldFillNPS) {
          $mdDialog
            .show({
              controller: require('./common/dialog/nps.dialog.controller.js'),
              template: require('./common/dialog/nps.dialog.html'),
              parent: angular.element(document.body),
              resolve: {
                ResolvedNPS: NPS =>
                  NPS.findOne({
                    filter: {
                      where: {
                        UserId: LoginService.currentUser.id,
                        opened: false,
                        latest: true
                      },
                      fields: { id: true }
                    }
                  }).$promise
              },
              multiple: true,
              fullscreen: true,
              clickOutsideToClose: false,
              escapeToClose: false
            })
            .then(() => {
              LoginService.currentUser.shouldFillNPS = false
            })
        } else if (LoginService.currentUser) {
          timeLogReportService.intervalIfNeedsToOpenTimeRecordDialog()
          // start authentication timeout service
          AuthenticationTimeoutService.start()
        }
      } else {
        AuthenticationTimeoutService.stop()
      }
    }
  )

  // track page view on state change
  $rootScope.$on('$stateChangeSuccess', function (event) {
    if (window.Intercom) {
      window.Intercom('update')
    }
    sendEngagement()
    if (
      $rootScope.appSettings.gaTrackingCode !== '' &&
      $rootScope.appSettings.gaEnable
    ) {
      $window.ga('send', 'pageview', $location.path())
    }
  })

  $rootScope.Page = Page

  $rootScope.currentTime = new Date().getTime()

  $rootScope.toggleSidenav = sidenavId => {
    $mdSidenav(sidenavId).toggle()
  }
  $rootScope.openSidenav = sidenavId => {
    $mdSidenav(sidenavId).open()
  }
  let deferred

  $rootScope.linkToDocument = (absolutePath = false) => {
    $rootScope.loadingProgress = true
    return $mdDialog
      .show({
        controller: DocumentCreateLinkController,
        template: require('./templates/dialogs/create-link.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {
          absolutePath
        },
        multiple: true,
        fullscreen: true,
        clickOutsideToClose: true
      })
      .then(data => (data && data.link ? data.link : ''))
  }

  $rootScope.insertMetabaseFrame = () =>
    $mdDialog
      .show({
        controller: MetabaseFrameController,
        template: require('./templates/dialogs/create-metabase.html'),
        parent: angular.element(document.body),
        targetEvent: '',
        locals: {},
        multiple: true,
        clickOutsideToClose: true
      })
      .then(data => data)
      .catch(() => {})

  $rootScope.textangularUpload = data => {
    if (
      jQuery('div[contenteditable="true"]') &&
      jQuery('div[contenteditable="true"]')[0]
    ) {
      jQuery('div[contenteditable="true"]')[0].focus()
    }
    if (data && data[0]) {
      deferred.resolve({
        resource_id: data[0].id,
        resource: data[0],
        url:
          '/api/containers/' + data[0].container + '/download/' + data[0].name
      })
    }
  }

  $window.uploadImage = $rootScope.uploadImage = selector => {
    deferred = $q.defer()
    $timeout(function () {
      $(selector || '.textangularUpload .angular-text-clickable-button').click()
    })
    return deferred.promise
  }

  $rootScope.uploadVideo = () => {
    deferred = $q.defer()
    $timeout(function () {
      $('.textangularUploadVideo .angular-text-clickable-button').click()
    })
    return deferred.promise
  }

  $rootScope.uploadStoryLine = () => {
    deferred = $q.defer()
    $timeout(function () {
      $('.textangularStoryLine .angular-text-clickable-button').click()
    })
    return deferred.promise
  }

  $rootScope.insertIFrame = () => {
    const confirm = $mdDialog
      .prompt()
      .title($translate.instant('TEXTANGULAR.INSERT_IFRAME_URL'))
      .multiple(true)
      .placeholder('URL')
      .ariaLabel('URL')
      .initialValue('')
      .targetEvent()
      .parent(angular.element(document.body))
      .ok($translate.instant('BUTTONS.OK'))
      .cancel($translate.instant('BUTTONS.CANCEL'))

    return $mdDialog.show(confirm).then(data => data)
  }

  $rootScope.showErrorToast = function showErrorToast (
    errorCode,
    translatedErrorCode = true
  ) {
    const mdToast = $mdToast.nextplus({
      position: $rootScope.toastLocation,
      parent: 'document.body',
      theme: 'error-toast',
      hideDelay: 3500
    })
    let text = $translate.instant(errorCode)
    if (translatedErrorCode) {
      text = $translate.instant('COMMON.GENERIC_ERROR_MESSAGE', {
        errorCode
      })
    }
    $mdToast.updateTextContent(text)
    $mdToast.show(mdToast)
  }

  // Template cache for ng-include
  // TODO: move it to the right components
  $templateCache.put(
    './modules/main/workflow/edit/sidenavs/main/main-sidenav.html',
    require('./modules/main/workflow/edit/sidenavs/main/main-sidenav.html')
  )
  $templateCache.put(
    './modules/main/workflow/edit/sidenavs/node-sidenav.html',
    require('./modules/main/workflow/edit/sidenavs/node-sidenav.html')
  )
  $templateCache.put(
    './modules/main/workflow/edit/sidenavs/workflow-sidenav.html',
    require('./modules/main/workflow/edit/sidenavs/workflow-sidenav.html')
  )
  $templateCache.put(
    './modules/main/workflow/edit/sidenavs/connection-sidenav.html',
    require('./modules/main/workflow/edit/sidenavs/connection-sidenav.html')
  )
  $templateCache.put(
    './modules/main/workflow/show/dialogs/workflow.kit.template.html',
    require('./modules/main/workflow/show/dialogs/workflow.kit.template.html')
  )
  $templateCache.put(
    './modules/main/workflow/show/dialogs/workflow.sign.template.html',
    require('./modules/main/workflow/show/dialogs/workflow.sign.template.html')
  )
  $templateCache.put(
    'app/main/apps/workflow/show/workflow.actions.template.html',
    require('./modules/main/workflow/show/workflow.actions.template.html')
  )
  $templateCache.put(
    'app/main/apps/workflow/show/dialogs/workflow.serial.template.html',
    require('./modules/main/workflow/show/dialogs/workflow.serial.template.html')
  )
  $templateCache.put(
    'app/main/apps/workflow/session/templates/tasks.html',
    require('./modules/main/workflow/session/templates/tasks.html')
  )
  $templateCache.put(
    'app/main/apps/workflow/session/templates/header.html',
    require('./modules/main/workflow/session/templates/header.html')
  )

  $templateCache.put(
    'app/main/apps/workflow/session/templates/navigator.html',
    require('./modules/main/workflow/session/templates/navigator.html')
  )
  $templateCache.put(
    'app/main/apps/workflow/session/templates/media.html',
    require('./modules/main/workflow/session/templates/media.html')
  )

  $templateCache.put(
    'app/main/apps/workflow/session/workflow/session/workflow-session-show-default.html',
    require('./modules/main/workflow/session/workflow-session-show-default.html')
  )
  $templateCache.put(
    'app/main/apps/workflow/session/workflow/session/workflow-session-show-timeline.html',
    require('./modules/main/workflow/session/workflow-session-show-timeline.html')
  )
  $templateCache.put(
    'app/main/apps/workflow/session/workflow/session/workflow-session-show-boston.html',
    require('./modules/main/workflow/session/workflow-session-show-boston.html')
  )
  $templateCache.put(
    'app/main/apps/workflow/session/workflow/session/workflow-session-show-realwear.html',
    require('./modules/main/workflow/session/workflow-session-show-realwear.html')
  )
  $templateCache.put(
    './toolbar.html',
    require('./modules/main/file-manager/views/toolbar.html')
  )
  $templateCache.put(
    './actions.html',
    require('./modules/main/file-manager/views/actions.html')
  )
  $templateCache.put(
    './content.html',
    require('./modules/main/file-manager/views/content.html')
  )
  $templateCache.put(
    './modules/main/workflow/session/sidenavs/filter/filter-sidenav.html',
    require('./modules/main/workflow/session/sidenavs/filter/filter-sidenav.html')
  )

  $rootScope.$on(
    '$stateChangeStart',
    async function (event, toState, toParams, fromState, fromParams) {
      await LoginService.getCurrentUserSingleton()
      if (LoginService.currentUser === null || !LoginService.currentUser.id) {
        const userAgent = $window.navigator.userAgent
        if (userAgent !== 'nextplus-pupeeter') {
          if (
            toState.name !== 'app.login' &&
            toState.name !== 'app.error-page' &&
            toState.name !== 'app.reset-password' &&
            toState.name !== 'app.validate-token' &&
            toState.name !== 'app.reset-sent'
          ) {
            event.preventDefault()
            const nextUrl = $state.href(toState.name, toParams, {absolute: true})
            if (isValidRedirectUrl(nextUrl)) {
              $state.go('app.login', {next: nextUrl})
            } else {
              $state.go('app.login')
            }
          }
        }
      } else if (LoginService.currentUser.resetPassword) {
        if (LoginService.currentUser.source === 'saml') {
          $timeout(function () {
            event.preventDefault()
            $state.go('app.set-new-pincode')
          }, 500)
        } else {
          $state.go('app.set-new-password')
        }
      } else if (toState.name === 'app.login') {
        event.preventDefault()
        $rootScope.afterLoginRedirect(LoginService.currentUser)
      }
    }
  )

  function isValidRedirectUrl(url) {
    if (!url) return false;
    const currentOrigin = $window.location.origin;
    try {
      const redirectUrl = new URL(url, currentOrigin);
      return redirectUrl.origin === currentOrigin;
    } catch (e) {
      return false;
    }
  }

  // 3rd Party Dependencies
  $rootScope.afterLoginRedirect = function (user) {
    if (user.resetPassword) {
      if (LoginService.currentUser.source === 'saml') {
        $timeout(function () {
          $state.go('app.set-new-pincode')
        }, 500)
      } else {
        $state.go('app.set-new-password')
      }
    } else if ($rootScope.loginRedirect) {
      $timeout(function () {
        $state.go(
          $rootScope.loginRedirect.state,
          $rootScope.loginRedirect.toParams || {}
        )
        $rootScope.loginRedirect = null
      })
    } else {
      const nextUrl = $location.search().next
      if (nextUrl && isValidRedirectUrl(nextUrl)) {
        $timeout(function () {
          $window.location.href = nextUrl
        })
      } else {
        $timeout(function () {
          $window.location.href = user.homepage
        })
      }
    }
  }

  // Activate loading indicator
  const stateChangeStartEvent = $rootScope.$on(
    '$stateChangeStart',
    function (event, toState, toParams, fromState, fromParams, options) {
      // console.log({ event, toState, toParams, fromState, fromParams, options })
      if (
        fromState.name === 'app.workflow.show-details' &&
        toState.name === 'app.workflow.show-details'
      ) {
        $animate.enabled(false)
      } else {
        $animate.enabled(true)
      }
      $rootScope.loadingProgress = true
      $mdDialog.cancel()
    }
  )

  $rootScope.$on(
    '$stateChangeStart',
    function (event, toState, toParams, fromState, fromParams, options) {
      // console.log(toState.name)
      if (
        (fromState.name === 'app.workflow.edit' ||
          fromState.name === 'app.workflow.create') &&
        $rootScope.workflowEdit
      ) {
        event.preventDefault()
        const leavePage = confirm(
          'If you leave before saving, your changes will be lost. Continue?'
        )
        if (leavePage) {
          $rootScope.workflowEdit = false
          $timeout(() => $state.go(toState.name, toParams), 100)
        }
      }
    }
  )

  // De-activate loading indicator
  const stateChangeSuccessEvent = $rootScope.$on(
    '$stateChangeSuccess',
    function (event, toState, toParams, fromState, fromParams, options) {
      // console.log({ event, toState, toParams, fromState, fromParams, options })
      $timeout(function () {
        $rootScope.loadingProgress = false
      })
    }
  )

  const stateChangeErrorEvent = $rootScope.$on(
    '$stateChangeError',
    function (event, toState, toParams, fromState, fromParams, error) {
      if (error.detail.status === 404) {
        $state.go('app.404')
      }
      $timeout(function () {
        $rootScope.loadingProgress = false
      })
    }
  )

  // console.log('runBlock');
  // Store state in the root scope for easy access
  $rootScope.state = $state

  $rootScope.$on(
    '$stateChangeSuccess',
    function (event, toState, toParams, fromState, fromParams) {
      $rootScope.stateParams = toParams
    }
  )

  $rootScope.queryMemory = new QueryCache(1000 * 30, 1000 * 120) // 30 secounds ttl , clean cache every 2 minutes

  $rootScope.$on(
    '$stateChangeStart',
    function (event, toState, toParams, fromState, fromParams) {
      if (toParams.externalUrl) {
        event.preventDefault()
        $window.open(toParams.externalUrl, toParams.target || '_blank')
        $timeout(function () {
          $rootScope.loadingProgress = false
        })
      }
    }
  )

  // Cleanup
  $rootScope.$on('$destroy', function () {
    stateChangeStartEvent()
    stateChangeSuccessEvent()
    stateChangeErrorEvent()
  })

  const userAgent = $window.navigator.userAgent

  const isNextPlusApp = function isNextPlusApp () {
    return /NextPlusAndroidApp/i.test($window.navigator.userAgent)
  }

  const getDeviceType = function getDeviceType (userAgent) {
    if (isNextPlusApp()) {
      if (/Realwear/.test(userAgent)) {
        return 'realwear'
      }
      if (/Tablet/.test(userAgent)) {
        return 'tablet'
      }
      if (/Mobile/.test(userAgent)) {
        return 'mobile'
      }
    }
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(userAgent)) {
      return 'tablet'
    } else if (
      /Mobile|iP(hone|od|ad)|Android|BlackBerry|IEMobile|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        userAgent
      )
    ) {
      return 'mobile'
    }
    return 'desktop'
  }

  const hasOCR = function hasOCR () {
    return (
      $window.androidApp &&
      (typeof $window.androidApp.readOCRText === 'function' ||
        typeof $window.androidApp.readOCRText2 === 'function' ||
        typeof $window.androidApp.sendMessageToAndroid === 'function')
    )
  }
  const isSupportPDF = function isSupportPDF () {
    let hasPDFViewer = false
    try {
      const pdf =
        navigator.mimeTypes && navigator.mimeTypes['application/pdf']
          ? navigator.mimeTypes['application/pdf'].enabledPlugin
          : 0
      if (pdf) hasPDFViewer = true
    } catch (e) {
      if (navigator.mimeTypes['application/pdf'] !== undefined) {
        hasPDFViewer = true
      }
    }

    return hasPDFViewer
  }

  const initLinkOverwrites = function initLinkOverwrites () {
    const findUpTag = el => {
      let elm = el
      while (elm) {
        if (
          elm.nodeName === 'LI' &&
          elm.hasAttribute('md-virtual-repeat') &&
          elm.children[0].nodeName === 'MD-AUTOCOMPLETE-PARENT-SCOPE'
        ) {
          return elm
        }
        elm = elm.parentNode
      }
      return null
    }
    const handleTap = function handleTap (e) {
      const elm = findUpTag(e.explicitOriginalTarget)
      if (elm) {
        const clickEvent = new MouseEvent('click', {
          view: window,
          bubbles: false,
          cancelable: false
        })
        elm.dispatchEvent(clickEvent)
      }
    }
    document.querySelector('body').addEventListener('tap', handleTap)
    document.querySelector('body').addEventListener(
      'click',
      function (e) {
        const anchor = e.target.closest('a')
        if (anchor !== null) {
          if (
            anchor.href.toLowerCase().endsWith('.pdf') &&
            (isNextPlusApp() || !isSupportPDF())
          ) {
            PanelHelper.openPanel({
              controller: require('./common/dialog/pdf-dialog/pdf.dialog.controller.js'),
              template: require('./common/dialog/pdf-dialog/pdf.dialog.html'),
              fullscreen: true,
              locals: {
                url: anchor.href
              },
              escapeToClose: true,
              clickOutsideToClose: true
            })
            e.preventDefault()
          } else if (anchor.target === '_blank') {
            window.location.href = anchor.href
            e.preventDefault()
          }
        }
      },
      false
    )
  }
  const initAppEvents = function initAppEvents () {
    initOCR()
    initLinkOverwrites()
  }
  const initOCR = function initOCR () {
    $rootScope.hasOcr = hasOCR()
    if ($rootScope.hasOcr) {
      if (
        $window.androidApp &&
        typeof $window.androidApp.setEventEmitter === 'function'
      ) {
        $window.androidApp.setEventEmitter(msg => {
          const messageJson = JSON.parse(msg)
          $rootScope.$broadcast(
            'NextPlusLens-' + messageJson.json.detail.value.uuid,
            JSON.parse(JSON.stringify(messageJson.json))
          )
        })
      }
    }
  }

  const checkUserAgentForNextPlusApp = function checkUserAgentForNextPlusApp () {
    let tryNumber = 1
    $rootScope.nextPlusApp = false
    const nextPlusCheckInterval = $interval(() => {
      $rootScope.nextPlusApp = isNextPlusApp()
      tryNumber++
      if ($rootScope.nextPlusApp || tryNumber >= 10) {
        $interval.cancel(nextPlusCheckInterval)
        if ($rootScope.nextPlusApp) {
          initAppEvents()
          if (!$rootScope.hasOcr) {
            const ocrInterval = $interval(() => {
              initAppEvents()
              if ($rootScope.hasOcr) {
                $interval.cancel(ocrInterval)
              }
            }, 3000)
          }
          import(/* webpackChunkName: "tap.js" */ './../vendors/tap')
            .then(mod => {})
            .catch(err => {
              throw new Error('Ooops, something went wrong, ' + err)
            })
        }
      }
    }, 2000)
  }

  const isTouchDevice = function isTouchDevice () {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    )
  }
  const setGlobalVariables = _.debounce(() => {
    $rootScope.isTouchDevice = isTouchDevice()
    const deviceType = getDeviceType(userAgent)
    $rootScope.isTabletOrMobile =
      $mdMedia('xs') ||
      $mdMedia('sm') ||
      $mdMedia('md') ||
      deviceType !== 'desktop'
  }, 200)
  setGlobalVariables()
  angular.element($window).bind('resize', setGlobalVariables)

  // Authentication timeout section - End

  checkUserAgentForNextPlusApp()
}

module.exports = runBlock
